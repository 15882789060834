import {
  Box,
  TextField,
  Typography,
  FormHelperText,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";

const wrapper = {
  height: "20px",
  width: "100%",
  mt: "2px",
};

const errorStyle = { paddingLeft: "0", mt: 0, fontStyle: "none" };

interface PasswordTextFieldProps {
  title?: string;
  name: string;
  value: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  touched: boolean;
  error: string | undefined;
  placeholder?: string;
}

const PasswordTextFiled: React.FC<PasswordTextFieldProps> = (props) => {
  const {
    title,
    name,
    value,
    handleChange,
    handleBlur,
    onFocus,
    touched,
    error,
    placeholder,
  } = props;
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      sx={{
        height: { xs: "82px", sm: "92px" },
        mb: { xs: "16px", sm: "0" },
        width: "100%",
      }}
    >
      <Typography variant="h6" sx={{ pb: "6px", fontWeight: "500" }}>
        {title || "Password"}
      </Typography>

      <TextField
        placeholder={placeholder || t("Login.Enter_password")}
        name={name}
        inputProps={{
          "data-cy": "password", // Cypress ID assigned to the input element
        }}
        type={showPassword ? "text" : "password"}
        value={value || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={onFocus}
        fullWidth
        sx={{
          "& .MuiOutlinedInput-root": {
            color: "#667085",
            fontWeight: "400",
          },
        }}
        error={touched && Boolean(error)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                data-cy="password-visible"
              >
                {showPassword ? (
                  <Visibility sx={{ fill: "#7c4ddd", fontSize: "18px" }} />
                ) : (
                  <VisibilityOff sx={{ fill: "#7c4ddd", fontSize: "18px" }} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box sx={wrapper}>
        {touched && Boolean(error) && (
          <FormHelperText error sx={errorStyle}>
            {touched && error}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export default PasswordTextFiled;
