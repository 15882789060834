import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import {
  generateIFrameSource,
  generateIFrameSourcePreview,
} from '../../utils/helpers.util';
import {
  changeTemplate,
  generateCode,
  generateFullStackApp,
  generatePrompt,
  getgeneratedPrompt,
  SaveUpdatedCode,
  templateSuggestions,
  updateViaAIRegenerate,
  updateViaPrompt,
} from '../thunk/promptThunk';
import { editFullStackApp } from '../thunk/projectThunk';

function handleThemePending(state: ThemeState) {
  state.loading = true;
  state.error = null;
}

function handleThemeRejected(state: ThemeState, action: any) {
  state.loading = false;
  state.error = action.payload;
}

export interface ThemeState {
  loading: boolean;
  isloading: boolean;
  updatedLoading: boolean;
  updatedAILoading: boolean;
  error: any;
  projectId: string;
  response: {};
  iframeData: any;
  filesSrc: any;
  iframePreviewData: any;
  projectRole: string;
  projectName: string;
  fullStackData: any;
  websiteType: string;
  generatePrompt: string;
  projectDetails: any;
  updatedCode: string;
  saveProjectStatus: boolean;
  saveProjectLoader: boolean;
  templatesArray: any[];
  editFullStackValue: string;
  editFullStackLoading: boolean;
  templateLoading: boolean;
}

const initialState: ThemeState = {
  loading: false,
  isloading: false,
  templateLoading: false,
  updatedLoading: false,
  updatedAILoading: false,
  error: null,
  projectId: null,
  response: null,
  iframeData: null,
  iframePreviewData: null,
  filesSrc: null,
  projectRole: 'owner',
  projectName: '',
  fullStackData: '',
  websiteType: 'static',
  generatePrompt: '',
  projectDetails: {},
  updatedCode: null,
  saveProjectStatus: true,
  saveProjectLoader: false,
  editFullStackLoading: false,
  templatesArray: [],
  editFullStackValue: null,
};

const promptSlice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    setUpdatedCode: (state, action) => {
      state.iframeData = action.payload;
    },
    setEditFullStackValue: (state) => {
      state.editFullStackValue = null;
    },
    setUpdatedCodeFiles: (state, action) => {
      const convrtForIframePreview = generateIFrameSourcePreview(
        action?.payload
      );
      state.filesSrc = action?.payload;
      state.iframePreviewData = convrtForIframePreview;
    },
    setWebisteType: (state, action) => {
      state.websiteType = action.payload;
    },
    setGeneratePrompt: (state, action) => {
      state.generatePrompt = action.payload;
    },
    setPropmtValues: (state) => {
      state.updatedCode = null;
    },
    setSaveProjectStatus: (state, action) => {
      state.saveProjectStatus = action.payload;
    },
    setRevertCode: (state, action) => {
      const convrtForIframe = generateIFrameSource(action?.payload);
      const convrtForIframePreview = generateIFrameSourcePreview(
        action?.payload
      );
      state.iframeData = convrtForIframe;
      state.iframePreviewData = convrtForIframePreview;
      state.filesSrc = action?.payload;
    },
    setPromptClear: (state) => {
      state.iframeData = null;
      state.projectDetails = {};
      state.projectName = '';
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ThemeState>) => {
    builder.addCase(generatePrompt.pending, handleThemePending);

    builder.addCase(generatePrompt.fulfilled, (state, action) => {
      state.projectId = action.payload.projectId;
      const convrtForIframe = generateIFrameSource(action?.payload?.response);
      const convrtForIframePreview = generateIFrameSourcePreview(
        action?.payload?.response
      );
      state.iframeData = convrtForIframe;
      state.iframePreviewData = convrtForIframePreview;

      state.loading = false;
    });

    builder.addCase(generatePrompt.rejected, handleThemeRejected);
    // generate code via claude
    builder.addCase(generateCode.pending, handleThemePending);

    builder.addCase(generateCode.fulfilled, (state, action) => {
      state.projectId = action.payload.projectId;
      const convrtForIframe = generateIFrameSource(action?.payload?.response);
      const convrtForIframePreview = generateIFrameSourcePreview(
        action?.payload?.response
      );
      state.iframeData = convrtForIframe;
      state.iframePreviewData = convrtForIframePreview;
      state.loading = false;
    });

    builder.addCase(generateCode.rejected, handleThemeRejected);
    // full stck
    builder.addCase(generateFullStackApp.pending, handleThemePending);

    builder.addCase(generateFullStackApp.fulfilled, (state, action) => {
      state.fullStackData = action?.payload?.project;
      state.loading = false;
    });

    builder.addCase(generateFullStackApp.rejected, handleThemeRejected);
    //  get teh generated prompt
    builder.addCase(getgeneratedPrompt.pending, handleThemePending);

    builder.addCase(getgeneratedPrompt.fulfilled, (state, action) => {
      if (action?.payload?.project?.type === 'Fullstack') {
        state.fullStackData = action?.payload?.project;
      } else {
        const convrtForIframe = generateIFrameSource(
          action?.payload?.project?.response
        );
        const convrtForIframePreview = generateIFrameSourcePreview(
          action?.payload?.project?.response
        );
        state.filesSrc = action?.payload?.project?.response;
        state.projectDetails = action?.payload;
        state.iframeData = convrtForIframe;
        state.iframePreviewData = convrtForIframePreview;

        state.projectRole = action?.payload?.project?.role;
        state.projectName = action?.payload?.project?.name;
      }
      state.loading = false;
    });
    builder.addCase(getgeneratedPrompt.rejected, handleThemeRejected);
    //  Update the design via prompt
    builder.addCase(updateViaPrompt.pending, (state) => {
      state.updatedLoading = true;
    });
    builder.addCase(updateViaPrompt.fulfilled, (state, action) => {
      state.updatedCode = action?.payload?.modelOutput;
      state.updatedLoading = false;
    });
    builder.addCase(updateViaPrompt.rejected, (state) => {
      state.updatedLoading = false;
    });
    //  Update the design via AIRegenerate
    builder.addCase(updateViaAIRegenerate.pending, (state) => {
      state.updatedAILoading = true;
      state.error = null;
    });
    builder.addCase(updateViaAIRegenerate.fulfilled, (state, action) => {
      state.updatedCode = action?.payload?.modelOutput;
      state.updatedAILoading = false;
    });
    builder.addCase(updateViaAIRegenerate.rejected, (state) => {
      state.updatedAILoading = false;
    });

    builder.addCase(SaveUpdatedCode.pending, (state) => {
      state.saveProjectLoader = true;
      state.error = null;
    });
    builder.addCase(SaveUpdatedCode.fulfilled, (state) => {
      state.saveProjectLoader = false;
      state.error = null;
    });
    builder.addCase(SaveUpdatedCode.rejected, (state, action) => {
      state.saveProjectLoader = false;
      state.error = action.payload;
    });
    // suggest the tamplates
    builder.addCase(templateSuggestions.pending, (state) => {
      state.templateLoading = true;
      state.error = null;
    });

    builder.addCase(templateSuggestions.fulfilled, (state, action) => {
      state.templatesArray = action?.payload?.templates;
      state.templateLoading = false;
    });

    builder.addCase(templateSuggestions.rejected, handleThemeRejected);
    // chnage the suggested tamplates
    builder.addCase(changeTemplate.pending, (state, action) => {
      state.templateLoading = false;
      state.error = action.payload;
    });

    builder.addCase(changeTemplate.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });

    builder.addCase(changeTemplate.rejected, handleThemeRejected);
    // Edit full stack
    builder.addCase(editFullStackApp.pending, (state) => {
      state.editFullStackLoading = true;
      state.error = null;
    });

    builder.addCase(editFullStackApp.fulfilled, (state, action) => {
      state.editFullStackValue = action?.payload?.payload?.response?.frontend;
      state.editFullStackLoading = false;
    });

    builder.addCase(editFullStackApp.rejected, (state, action) => {
      state.editFullStackLoading = false;
      state.error = action.payload;
    });
  },
});

export const {
  setUpdatedCode,
  setUpdatedCodeFiles,
  setWebisteType,
  setGeneratePrompt,
  setPropmtValues,
  setSaveProjectStatus,
  setPromptClear,
  setEditFullStackValue,
  setRevertCode,
} = promptSlice.actions;

export default promptSlice.reducer;
