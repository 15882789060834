import http from "../../services/http.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

interface Plan {
    _id: string;
    name: string;
    monthly_price: number;
    yearly_price: number;
    features: Record<string, any>;
    createdAt: string;
    updatedAt: string;
    plan_merchant_id?: string;
}

interface PlansResponse {
    success: boolean;
    message: string;
    plans: Plan[];
}

interface SubscribePlanPayload {
    planId: string;
    billing_cycle: string;
    payment_token: string;
    address: string;
    country: string;
    organizationId?: string;
}
interface SubscribePlanResponse {
    success: boolean;
    message: string;
}

export const getPlans = createAsyncThunk<
    PlansResponse,
    void,
    { rejectValue: any }
>("plans/getPlans", async (_, thunkAPI) => {
    const [response, error] = await http.get("/plans");
    if (error) {
        return thunkAPI.rejectWithValue(error.response?.data || "Something went wrong");
    }
    return response.data;
});

export const subscribePlan = createAsyncThunk<
    SubscribePlanResponse,
    SubscribePlanPayload,
    { rejectValue: any }
>("plans/subscribePlan", async (data, thunkAPI) => {
    const [response, error] = await http.post("/plans/subscribe", data);
    if (error) {
        return thunkAPI.rejectWithValue(error.response?.data || "Subscription failed");
    }
    return response.data;
});
