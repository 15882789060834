import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import storage from "redux-persist/lib/storage";
import themeReducer from "./slices/themeSlice";
import authReducer from "./slices/authSlice";
import promptReducer from "./slices/promptSlice";
import otpReducer from "./slices/optSlice";
import persistStore from "redux-persist/es/persistStore";
import userSlice from "./slices/userSlice";
import toolsSlice from "./slices/toolsSlice";
import servicesSlice from "./slices/servicesSlice";
import collaboratorSlice from "./slices/collaboratorSlice";
import projectsSlice from "./slices/projectsSlice";
import commentSlice from "./slices/commentSlice";
import deploymentSlice from "./slices/deploymentSlice";
import chatbotSlice from "./slices/chatbotSlice";
import paymentSlice from "./slices/paymentSlice";

const persistConfig = {
  key: "auth",
  version: 1,
  storage,
};

const reducer = combineReducers({
  auth: authReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    auth: persistedReducer,
    prompt: promptReducer,
    otp: otpReducer,
    user: userSlice,
    tools: toolsSlice,
    services: servicesSlice,
    collaborator: collaboratorSlice,
    project: projectsSlice,
    comments: commentSlice,
    deployment: deploymentSlice,
    chatbot: chatbotSlice,
    payment: paymentSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "persist/PERSIST",
          "tools/setCoordinates",
          "tools/setUpdateHTMLDocument",
        ],
        ignoredPaths: ["tools.updateHTMLDocument"],
      },
    }),
});
export const persistor = persistStore(store);

export type RootState = {
  theme: ReturnType<typeof themeReducer>;
  auth: ReturnType<typeof persistedReducer>;
  prompt: ReturnType<typeof promptReducer>;
  otp: ReturnType<typeof otpReducer>;
  user: ReturnType<typeof userSlice>;
  tools: ReturnType<typeof toolsSlice>;
  services: ReturnType<typeof servicesSlice>;
  collaborator: ReturnType<typeof collaboratorSlice>;
  project: ReturnType<typeof projectsSlice>;
  comments: ReturnType<typeof commentSlice>;
  deployment: ReturnType<typeof deploymentSlice>;
  chatbot: ReturnType<typeof chatbotSlice>;
  payment: ReturnType<typeof paymentSlice>;
};

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
