import * as React from 'react';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { GuestProfileImage } from '@/assets/images';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setClearState } from '@/redux/slices/userSlice';
import { useLogoutFunction } from '@/hooks/Logout';
import { Check, LayoutDashboard, LogOut, Plus, Settings, User } from 'lucide-react';
import AddOrganizationModel from '@/components/model/AddOrganizationModel';

const ProfileMenu = () => {
  const ProfileData = useAppSelector((state) => state.user.user);
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  const openMenu = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const logoutHook = useLogoutFunction();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, borderRadius: '8px' }}
            aria-controls={openMenu ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
          >
            {ProfileData?.type === 'guest' ? (
              <Avatar sx={{ width: 48, height: 48 }}>
                <img
                  src={GuestProfileImage}
                  alt="Guest Profile"
                  width="100%"
                  height="100%"
                />
              </Avatar>
            ) : ProfileData?.profileImage ? (
              <Avatar
                sx={{
                  width: 48,
                  height: 48,
                }}
              >
                <img
                  src={ProfileData?.profileImage}
                  alt="profile"
                  width="100%"
                  height="100%"
                />
              </Avatar>
            ) : (
              <Avatar sx={{ ...AvatarStyle(location), width: 48, height: 48 }}>
                {' '}
                {ProfileData?.fullName[0]?.toUpperCase() || 'F'}
              </Avatar>
            )}
            <ExpandMoreRoundedIcon
              sx={{
                color: location.pathname.startsWith('/create-website')
                  ? '#fff'
                  : '',
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              maxWidth: '456px', // Set the width of the menu to 456px
              '& .MuiAvatar-root': {
                width: 48,
                height: 48,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ display: 'flex', p: 2, alignItems: 'center' }}>
          <Box sx={{ mr: 0.5 }}>
            {ProfileData?.profileImage ? (
              <Avatar
                sx={{
                  width: 48,
                  height: 48,
                }}
              >
                <img
                  src={ProfileData?.profileImage}
                  alt="profile"
                  width="100%"
                  height="100%"
                />
              </Avatar>
            ) : (
              <Avatar sx={{ ...AvatarStyle(location), width: 48, height: 48 }}>
                {ProfileData?.fullName[0]?.toUpperCase() || 'F'}
              </Avatar>
            )}
          </Box>
          <Box sx={{ maxWidth: 'calc(456px - 80px)', overflow: 'hidden' }}>
            <Tooltip title={ProfileData?.fullName || 'faiq'} placement="top">
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 500,
                  maxWidth: '180px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  color: '#1E293B',
                }}
              >
                {ProfileData?.fullName || 'Unknown'}
              </Typography>
            </Tooltip>
            <Tooltip
              title={ProfileData?.email || 'faiq@faiq.com'}
              placement="bottom"
            >
              <Typography
                variant="subtitle1"
                sx={{
                  color: '#667085',
                  maxWidth: '180px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {ProfileData?.email || 'faiq@faiq.com'}
              </Typography>
            </Tooltip>
          </Box>
        </Box>

        {false && (
          <>
            <Divider />
            <Box
              sx={{
                fontSize: '14px',
                fontWeight: '400',
                width:"100%",
                color: '#667085',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: '12px !important',
                  fontWeight: '400 !important',
                  ml:"16px",
                  mt:"12px"
                }}
              >
                Organizations
              </Typography>
              {organizationMapData?.map((item,index) => (
                 <MenuItem sx={{width:"100%",}}>
                <Box
                key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Box sx={{ my: '8px' }}>
                    <Typography variant="subtitle1" sx={{ color: '#1E293B' }}>
                    {item?.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: '12px !important',
                        fontWeight: '400 !important',
                        mt: '4px',
                      }}
                    >
                  {item?.plan}
                    </Typography>
                  </Box>
                {index === 1 &&  <IconButton>
                    <Check height="20px" width="20px"/>
                  </IconButton>}
                </Box>
                </MenuItem>
              ))}
            </Box>
          </>
        )}
        {true && (
          <>
            <Divider />
            <MenuItem
              onClick={() => {
                handleClose();
                setTimeout(() => {
                  navigate('/my-projects');
                }, 5);
              }}
              sx={{
                fontSize: '14px',
                fontWeight: '400',
                color: '#667085',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: '12px !important',
                  fontWeight: '400 !important',
                }}
              >
                Your Organization
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Box sx={{ my: '8px' }}>
                  <Typography variant="subtitle1" sx={{ color: '#1E293B' }}>
                    icod Team
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '12px !important',
                      fontWeight: '400 !important',
                      mt: '4px',
                    }}
                  >
                    Enterprise Plan
                  </Typography>
                </Box>
                <IconButton>
                  <Settings height="20px" width="20px" />
                </IconButton>
              </Box>
            </MenuItem>
          </>
        )}

        {true && (
          <>
            <MenuItem
              onClick={() => {
                handleClose();
                setTimeout(() => {
                  navigate('/my-projects');
                }, 5);
              }}
              sx={{ fontSize: '14px', fontWeight: '400', color: '#667085' }}
            >
              <LayoutDashboard style={{ marginRight: '12px' }} height="20px" width="20px" />
              {t('LandingPage.Dashboard')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setTimeout(() => {
                  navigate('/profile');
                }, 5);
              }}
              sx={{ fontSize: '14px', fontWeight: '400', color: '#667085' }}
            >
              <User style={{ marginRight: '12px' }} height="20px" width="20px" />
              {t('Dashboard.Profile')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setOpen(true);
              }}
              sx={{ fontSize: '14px', fontWeight: '400', color: '#667085' }}
            >
              <Plus style={{ marginRight: '12px' }} height="20px" width="20px"/>
              Create organization
            </MenuItem>
          </>
        )}
        <Divider />

        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            handleClose();
            logoutHook();
            navigate('/');
            dispatch(setClearState());
          }}
          sx={{ fontSize: '14px', fontWeight: '400', color: '#667085' }}
        >
         <LogOut style={{ marginRight: '12px' }} height="20px" width="20px" />
          {t('LandingPage.Logout')}
        </MenuItem>
      </Menu>
      <AddOrganizationModel open={open} setOpen={setOpen} />
    </React.Fragment>
  );
};

export default ProfileMenu;

const AvatarStyle = (location) => ({
  width: location.pathname === '/create-website' ? 36 : 40,
  height: location.pathname === '/create-website' ? 36 : 40,
  background: location.pathname === '/create-website' ? '#fff' : '#7C4CE1',
  color: location.pathname === '/create-website' ? '#7C4CE1' : '#fff',
});

const organizationMapData =[
  {name:"Techtimize",plan:"Team Plan"},
  {name:"Big Entities",plan:"Enterprise Plan"}
]
