import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import {CircleSmall} from "lucide-react"

const ListComp = (props) => {
    const {arr}=props
  return (
   <>
    {arr && <List>
                  {arr?.map((item, index) => (
                    <ListItem key={index} sx={{py:"0"}}>
                      <ListItemIcon sx={{padding:"0",minWidth:"20px"}}>
                      <CircleSmall strokeWidth={12}     height="8px" width="8px" />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>}</>
  )
}

export default ListComp