import { ErrorIcons } from '@/assets/images';
import { Box, keyframes, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect, useState } from 'react';

// Animation keyframes
const fillBar = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

const growCheck = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

// const fadeIn = keyframes`
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const StatsPasswordUpdate = (props) => {
  const { passwordValue   } = props;

  // const [open, setOpen] = React.useState(false);
    const [passwordStrength, setPasswordStrength] = useState({
      hasEightChars: false,
      hasUpperCase: false,
      hasNumber: false,
      hasSpecial: false,
      strengthLevel: 0, // 0-4: Very Weak, Weak, Medium, Strong
    });
  
    // Animation states for each validation item
    const [animations, setAnimations] = useState({
      hasEightChars: false,
      hasUpperCase: false,
      hasNumber: false,
      hasSpecial: false,
    });

    // Calculate password strength whenever the password changes
 const checkPasswordStrength = (password) => {
  const newState = {
    hasEightChars: password.length >= 8,
    hasUpperCase: /[A-Z]/.test(password),
    hasNumber: /[0-9]/.test(password),
    hasSpecial: /[^A-Za-z0-9]/.test(password),
  };

  // Calculate strength level (0-4)
  let strengthLevel = 0;
  if (password.length > 0) strengthLevel = 1; // Very Weak
  if (newState.hasEightChars) strengthLevel = 2; // Weak
  if (
    (newState.hasUpperCase && newState.hasNumber) ||
    (newState.hasUpperCase && newState.hasSpecial) ||
    (newState.hasNumber && newState.hasSpecial)
  )
    strengthLevel = 3; // Medium
  if (
    newState.hasEightChars &&
    newState.hasUpperCase &&
    newState.hasNumber &&
    newState.hasSpecial
  )
    strengthLevel = 4; // Strong

  // Track which validations changed to trigger animations
  const newAnimations = { ...animations };

  // For each validation rule, if it changed from false to true, trigger animation
  Object.keys(newState).forEach((key) => {
    if (!animations[key] && newState[key]) {
      newAnimations[key] = true;
    }
  });

  setAnimations(newAnimations);
  setPasswordStrength({ ...newState, strengthLevel });
};

  // Update password strength when newPassword changes
  useEffect(() => {
    checkPasswordStrength(passwordValue);
  }, [passwordValue]);
  
 // Get strength data for UI
  const getStrengthData = () => {
    const { strengthLevel } = passwordStrength;
    return (
      mapPassowrdData[strengthLevel - 1] || { title: "Very Weak", color: "#e40808" }
    );
  };

  const strengthData = getStrengthData();

  return (
    <Box
      sx={{
        pt: '2rem',
        pl: '1rem',
        display: { xs: 'none', sm: 'block' },
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{
            color: passwordValue ? strengthData.color : '#E0E0E0',
            transition: 'color 0.5s ease',
            ...(passwordStrength.strengthLevel > 0 && {
              animation: `${pulse} 0.5s ease`,
            }),
          }}
        >
          {passwordValue ? strengthData.title : 'Weak Password'}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '12px',
            my: 2,
          }}
        >
          {/* Strength bars with animation */}
          {[1, 2, 3, 4].map((level) => (
            <Box
              key={level}
              sx={{
                width: { xs: '45px', md: '60px' },
                height: '4px',
                backgroundColor: '#E0E0E0',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              {passwordStrength.strengthLevel >= level && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    backgroundColor:
                    mapPassowrdData[passwordStrength.strengthLevel - 1]?.color ||
                      '#E0E0E0',
                    animation: `${fillBar} 0.5s ease forwards`,
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">
            Your Password must contain:
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '20px',
                marginBottom: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {passwordStrength.hasEightChars ? (
                    <CheckCircleIcon
                      sx={{
                        color: 'green',
                        animation: animations.hasEightChars
                          ? `${growCheck} 0.5s ease`
                          : 'none',
                      }}
                    />
                  ) : (
                    <img src={ErrorIcons} alt="error" />
                  )}
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    transition: 'color 0.3s ease',
                    color: passwordStrength.hasEightChars ? 'green' : 'inherit',
                  }}
                >
                  8+ Characters
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {passwordStrength.hasUpperCase ? (
                    <CheckCircleIcon
                      sx={{
                        color: 'green',
                        animation: animations.hasUpperCase
                          ? `${growCheck} 0.5s ease`
                          : 'none',
                      }}
                    />
                  ) : (
                    <img src={ErrorIcons} alt="error" />
                  )}
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    transition: 'color 0.3s ease',
                    color: passwordStrength.hasUpperCase ? 'green' : 'inherit',
                  }}
                >
                  1 Uppercase
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {passwordStrength.hasNumber ? (
                    <CheckCircleIcon
                      sx={{
                        color: 'green',
                        animation: animations.hasNumber
                          ? `${growCheck} 0.5s ease`
                          : 'none',
                      }}
                    />
                  ) : (
                    <img src={ErrorIcons} alt="error" />
                  )}
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    transition: 'color 0.3s ease',
                    color: passwordStrength.hasNumber ? 'green' : 'inherit',
                  }}
                >
                  1 Numeric
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <Box
                  sx={{
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {passwordStrength.hasSpecial ? (
                    <CheckCircleIcon
                      sx={{
                        color: 'green',
                        animation: animations.hasSpecial
                          ? `${growCheck} 0.5s ease`
                          : 'none',
                      }}
                    />
                  ) : (
                    <img src={ErrorIcons} alt="error" />
                  )}
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    transition: 'color 0.3s ease',
                    color: passwordStrength.hasSpecial ? 'green' : 'inherit',
                  }}
                >
                  1 Special Character
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StatsPasswordUpdate;

export const mapPassowrdData = [
  { title: "Very Weak", color: "#e40808" },
  { title: "Weak", color: "#e40808" },
  { title: "Medium", color: "#ffd800" },
  { title: "Strong", color: "#2cb117" },
];
