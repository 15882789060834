import React, { useEffect } from 'react';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import i18next from 'i18next';
import { useAppDispatch } from '@/redux/store';
import { setDirection } from '@/redux/slices/themeSlice';
import { useLocation } from 'react-router-dom';
import { setLanguage } from '@/redux/slices/authSlice';
import { mapLanguageData } from './LanguageData';

interface LanguageDropdownProps {}

const LanguageDropdown: React.FC<LanguageDropdownProps> = () => {
  const dispatch = useAppDispatch();
  const i18nextLng = localStorage.getItem('i18nextLng') || 'en';
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (i18nextLng === 'ar') dispatch(setDirection('ar' as string));
  }, [i18nextLng]);
  const flagData = mapLanguageData?.filter((el) => el.value === i18nextLng);

  return (
    <>
      <Box
        sx={ImageBoxStyle(location)}
        onClick={handleClick}
        component={'button'}
      >

        {flagData[0]?.svgSrc}

        {/* {location.pathname.startsWith("/builder-webApp") ||
        location.pathname.startsWith("/icod-builder") ||
        location.pathname.startsWith("/my-projects") ||
        location.pathname.startsWith("/profile") ||
        location.pathname.startsWith("/create-website") ||
        location.pathname === "/" ? null : (
          <Typography
            variant="h6"
            sx={{ fontWeight: "500", display: { xs: "none", sm: "block" } }}
          >
            {t("utalities.Language")}
          </Typography>
        )} */}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-paper': {
            width: '200px',
            p:
              location.pathname.startsWith('/create-website') ||
              location.pathname.startsWith('/builder-webApp')
                ? '7px'
                : '6px',
            border: '1px solid  #DFE4EA',
            borderRadius: '8px',
            backgroundColor: '#F9FAFB',
            ':hover': {
              backgroundColor: '#F9FAFB',
            },
          },
        }}
      >
        {mapLanguageData?.map((item, index) => (
          <MenuItem
            onClick={() => {
              i18next.changeLanguage(item?.value as string);
              dispatch(setLanguage(item?.value as string));
              dispatch(setDirection(item?.value as string));
              handleClose();
            }}
            key={index}
            value={item?.value}
            sx={{
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {item?.svgSrc}
              <Typography variant="subtitle1" sx={{ ml: '8px' }}>
                {item?.name}
              </Typography>
            </Box>

            {item?.value === i18nextLng && (
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_810_46897)">
                  <path
                    d="M3.33398 8.00008L6.66732 11.3334L13.334 4.66675"
                    stroke="#374151"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_810_46897">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageDropdown;

const ImageBoxStyle = (location) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent:
    location.pathname.startsWith('/icod-builder') ||
    location.pathname.startsWith('/builder-webApp')
      ? 'center'
      : 'center',
  cursor: 'pointer',
  padding:
    location.pathname.startsWith('/icod-builder') ||
    location.pathname.startsWith('/builder-webApp')
      ? '6px'
      : '7px',
  width: '40px',
  height: '40px',
  border: 'none',
  borderRadius: '8px',
  backgroundColor: 'transparent',
  marginRight: location.pathname.startsWith('/create-website') ? '0' : '16px',
  transition: 'all .3s',
  ':hover': {
    backgroundColor: location.pathname.startsWith('/create-website')
      ? '#ae87ec'
      : '#F3F4F6 ',
  },
});
