import { Box, Button, Checkbox, Dialog, DialogActions, DialogTitle, FormControlLabel, Typography } from '@mui/material'
import TermAndCondation from './TermAndCondation'
import { useTranslation } from 'react-i18next';
import { motion } from "framer-motion"; 

const DialogModel = (props) => {
    const {termsDialogOpen,handleCloseTermsDialog,handleTermsAcceptance,termsAccepted,handleOpenTermsDialog}=props
      const { t } = useTranslation();
  return (
    <>    {/* Terms and Conditions Dialog */}
     <Box sx={{ width: '100%', mt: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={termsAccepted}
                      onChange={() => {}}
                      onClick={handleOpenTermsDialog}
                    />
                  }
                  label={
                    <Typography>
                      {t("Sign_Up.I_accept_the")}{" "}
                      <Typography 
                        component="span" 
                        sx={{ 
                          color: "#7C4CE1", 
                          cursor: "pointer",
                          textDecoration: "underline"
                        }}
                        onClick={handleOpenTermsDialog}
                      >
                        {t("Sign_Up.Terms_and_Conditions")}
                      </Typography>
                    </Typography>
                  }
                />
              </Box>
    <Dialog
      open={termsDialogOpen}
      onClose={handleCloseTermsDialog}
      aria-labelledby="terms-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        <DialogTitle id="terms-dialog-title">
          {t("Sign_Up.Terms_and_Conditions")}
        </DialogTitle>
       <TermAndCondation/>
        <DialogActions>
          <Button onClick={handleCloseTermsDialog} color="secondary">
            {t("Sign_Up.Cancel")}
          </Button>
          <Button 
            onClick={handleTermsAcceptance} 
            color="primary" 
            variant="contained"
          >
            {t("Sign_Up.Accept")}
          </Button>
        </DialogActions>
      </motion.div>
    </Dialog></>
  )
}

export default DialogModel