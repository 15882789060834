import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

interface getChatbotHistoryResponse {
  message: string;
  response: {
    messages: any;
  };
  snapshot: any;
}
interface getChatbotRevertCodeResponse {
  message: string;
  code: any;
}
interface Chatbot_API_Fun_Response {
  message: string;
  response: any;
}

export const getChatbotHistory = createAsyncThunk<
  getChatbotHistoryResponse,
  {
    project_id: string;
  },
  { rejectValue: any }
>(
  'chatbot/get_history',
  async (
    data: {
      project_id: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_AI_CHATBOT_URL
        }chatbot_static_history?project_id=${data?.project_id}`
      );
      return response.data; // Return the response if successful
    } catch (error: any) {
      // Error handling
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || 'An error occurred'
      );
    }
  }
);
export const getChatbotRevertCode = createAsyncThunk<
  getChatbotRevertCodeResponse,
  {
    project_id: string;
    snapshot_index: number;
  },
  { rejectValue: any }
>(
  'chatbot/get_code',
  async (
    data: {
      project_id: string;
      snapshot_index: number;
    },
    thunkAPI
  ) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_AI_CHATBOT_URL}chat_snapshot`,
        { project_id: data?.project_id, snapshot_index: data?.snapshot_index }
      );
      return response.data; // Return the response if successful
    } catch (error: any) {
      // Error handling
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || 'An error occurred'
      );
    }
  }
);

export const Chatbot_API_Fun = createAsyncThunk<
  Chatbot_API_Fun_Response,
  {
    user_prompt: string;
    project_id: string;
    website_code: any;
    code_snippet: any;
  },
  { rejectValue: any }
>(
  'chatbot/chat_with_chatbot',
  async (
    data: {
      user_prompt: string;
      project_id: string;
      website_code: any;
      code_snippet: any;
    },
    thunkAPI
  ) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_APP_AI_CHATBOT_URL}chatbot_static_v1`,
        data
      );
      return response.data; // Return the response if successful
    } catch (error: any) {
      // Error handling
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || 'An error occurred'
      );
    }
  }
);
