import { Box, Button, useTheme } from "@mui/material";
import { LogoImage } from "@/assets/images";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import SideBar from "../sidebar";
import React from "react";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import { useAppSelector } from "@/redux/store";
import { useLogoutFunction } from "@/hooks/Logout";

import LanguageDropdown from "@/components/languageDropdown";
import { useTranslation } from "react-i18next";
import ProfileMenu from "./components/ProfileMenu";
// import Collaborators from "./components/collaborators";

const MainNavbar = () => {
  const theme = useTheme();
  const logout = useLogoutFunction();
  const { t } = useTranslation();
  const naviagte = useNavigate();
  const { accessToken } = useAppSelector((state) => state.auth.auth);
  const isAuthenticated = accessToken;
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={FirstBoxStyle({ bgcolor: theme?.palette?.background?.paper })}>
      <Box sx={ContainerStyle}>
        {" "}
        <Box sx={BoxStyle}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                display: { xs: "block", md: "none" },
                height: "24px",
                width: "24px",
                mr: "8px",
              }}
            >
              {" "}
              <MenuIcon
                sx={{ color: "#7C4CE1" }}
                onClick={toggleDrawer(true)}
              />
            </Box>
            <Box
              sx={{
                height: { xs: "18.2px", sm: "23.4px", md: "26px" },
                width: { xs: "89.6px", sm: "115.2px", md: "128px" },
              }}
            >
              <Link
                to="/"
                style={{
                  display: "block",
                  height: "100%",
                  width: "100%",
                }}
              >
                <img
                  src={LogoImage}
                  alt="logo"
                  style={{ height: "100%", width: "100%", cursor: "pointer" }}
                />
              </Link>
            </Box>
            <Box sx={{ ml: "24px", display: { xs: "none", sm: "block" } }}>
             
              <Link
                to={"/"}
                onClick={(e) => {
                  e.preventDefault();
                  naviagte("/", {
                    state: { scrollTo: "icode-contact-us-section" },
                  });
                }}
                style={{ color: "#667085", margin: "0  14px 0 14px" }}
              >
                {t("LandingPage.Contact_US")}
              </Link>
              <Link
                to={"/pricing"}
                style={{ color: "#667085", margin: "0  14px 0 14px" }}
              >
                {t("Pricing.Pricing")}
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* <LightDarkTheme /> */}
            <LanguageDropdown />

            {isAuthenticated ? (
              <ProfileMenu />
            ) : (
              <Button
                variant="outlined"
                sx={PreviewButtonStyle}
                onClick={() => {
                  isAuthenticated ? logout() : naviagte("/login");
                }}
              >
                {isAuthenticated
                  ? t("LandingPage.Logout")
                  : t("LandingPage.Login")}
              </Button>
            )}
            {!isAuthenticated && (
              <PrimaryButton
                style={{
                  display: { xs: "none", md: "flex" },
                  minWidth: "110px",
                  height: "40px",
                  mr: "8px",
                }}
                compLeft={true}
                text={
                  isAuthenticated
                    ? t("LandingPage.Dashboard")
                    : t("LandingPage.Sign_Up")
                }
                onClick={() => {
                  isAuthenticated
                    ? naviagte("/my-projects")
                    : naviagte("/signup");
                }}
              />
            )}
            {/* Avatar and email and user name */}
          </Box>
        </Box>
      </Box>

      <SideBar open={open} handleClose={handleClose} />
    </Box>
  );
};

export default MainNavbar;

const ContainerStyle = {
  height: "72px",
  position: "sticky",
  top: 0,
  width: "100%",
};
const BoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
  width: "100%",
};
const PreviewButtonStyle = {
  borderColor: "#fff",
  display: { xs: "none", md: "flex" },
  mr: "16px",
  border: "2px solid #7C4CE1",
  color: "#7C4CE1",
  minWidth: "100px",
  minHeight: "40px",
  whiteSpace: "nowrap",
};
const FirstBoxStyle = ({ bgcolor }) => ({
  backgroundColor: bgcolor,
  zIndex: 1200,
  height: "72px",
  width: "100%",
});
