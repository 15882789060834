import { Googleicon } from "@/assets/images";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Box, Button, Dialog, Divider, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setGuestAuthModel } from "@/redux/slices/userSlice";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { loginSchema, SignupSchema } from "@/utils/schema";
import PrimaryTextField from "../textField/PrimaryTextField";
import PasswordTextFiled from "../textField/PasswordTextFiled";
import toast from "react-hot-toast";
import {  login, signup } from "@/redux/thunk/authThunk";
import DialogModel from "@/pages/auth/signup/DialogModel";

const AuthModel = () => {
  const { t } = useTranslation();
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { loading } = useAppSelector((state) => state.auth.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { guestAuthModel } = useAppSelector((state) => state.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const [switchAuth, setSwitchAuth] = useState(0);
  const collaborationId = searchParams.get("collaborationId");
  const handleClose = () => {
    dispatch(setGuestAuthModel(false));
  };
  const ProfileData = useAppSelector((state) => state.user.user);

  const initialValues = useMemo(
    () => ({
      fullName: "",
      email: "",
      password: "",
    }),
    []
  );
  const formik = useFormik({
    initialValues,
    validationSchema: switchAuth === 1 ? SignupSchema : loginSchema,
    onSubmit: async () => {
      const data: {
        email: string;
        password: string;
        collaborationId?: string;
        guestId?: string;
      } = {
        email: values.email.toLowerCase().trim(),
        password: values.password.trim(),
      };
      const dataSignUp: {
        fullName: string;
        email: string;
        password: string;
        collaborationId?: string;
        guestId?: string;
      } = {
        email: values.email.toLowerCase().trim(),
        password: values.password.trim(),
        fullName: values.password.trim(),
      };
      if (switchAuth === 1) {
        dataSignUp.fullName = values?.fullName?.trim();
      }
      if (collaborationId) {
        data.collaborationId = collaborationId;
        dataSignUp.collaborationId = collaborationId;
      }
      if (ProfileData?.type === "guest") {
        data.guestId = ProfileData?._id;
        dataSignUp.guestId = ProfileData?._id;
      }

      if (switchAuth === 1) {
        const resultAction = await dispatch(signup(dataSignUp));
        if (signup.fulfilled.match(resultAction)) {
          // toast.success(
          //   resultAction?.payload?.message || "SignUp Successfully"
          // );
          navigate("/verify-email")
          handleClose();
        } else if (signup.rejected.match(resultAction)) {
          toast.error(resultAction?.payload?.reason || "Something went wrong");
        }
      } else {
        const resultAction = await dispatch(login(data));
        if (login.fulfilled.match(resultAction)) {
          toast.success(resultAction?.payload?.message || "Login Successfully");

          handleClose();
        } else if (login.rejected.match(resultAction)) {
          toast.error(
            resultAction?.payload?.reason || "Email or password is incorrect"
          );
        }
      }

      formik.resetForm();
      setSwitchAuth(0);
    },
  });

  // const GuestUserFun = async () => {
  //   const resultAction = await dispatch(GuestUser());
  //   if (GuestUser.fulfilled.match(resultAction)) {
  //     toast.success(resultAction?.payload?.message || "Login Successfully");
  //     navigate("/create-website");
  //     handleClose();
  //   } else if (GuestUser.rejected.match(resultAction)) {
  //     toast.error(resultAction?.payload?.reason || "Something went wrong");
  //   }
  //   formik.resetForm();
  // };

  const { values, handleBlur, handleSubmit, handleChange, touched, errors } =
    formik;

  useEffect(() => {
    try {
      const oAuthSuccess = JSON.parse(searchParams.get("oauth_success"));
      if (!oAuthSuccess && searchParams.has("oauth_success"))
        toast.error("Login using email and password");
    } catch (error) {
      searchParams.delete("oauth_success");
      setSearchParams(searchParams);
    }
  }, []);
  const openGoogleConsentScreen = useCallback(() => {
    if (switchAuth === 0 && ProfileData?.type !== "guest") {
      window.open(
        `${import.meta.env.VITE_APP_BASE_URL_DEV}/auth/google`,
        "_self"
      );
      navigate("/create-website");
    } else {
      window.open(
        `${import.meta.env.VITE_APP_BASE_URL_DEV}/auth/google?guestId=${
          ProfileData?._id
        }`,
        "_self"
      );
    }
  }, []);

  
  const handleOpenTermsDialog = () => {
    setTermsDialogOpen(true);
  };

  const handleCloseTermsDialog = () => {
    setTermsDialogOpen(false);
  };

  const handleTermsAcceptance = () => {
    setTermsAccepted(true);
    handleCloseTermsDialog();
  };

  return (
    <Dialog onClose={handleClose} open={guestAuthModel}>
      <Box sx={BoxStyle}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        >
          <Box
            component={"form"}
            onSubmit={handleSubmit}
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {ProfileData?.type === "guest" && (
              <Box sx={{ px: { xs: "12px", sm: "24px" }, mb: "12px" }}>
                <Typography variant="h4" textAlign={"center"}>
                  {t("AuthModel.Save_Progress")}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "400" }}
                  textAlign={"center"}
                >
                  {t("AuthModel.Create_an_account")}
                </Typography>
              </Box>
            )}
            {switchAuth === 1 && (
              <PrimaryTextField
                title={t("Sign_Up.Name")}
                placeholder={t("Sign_Up.Enter_Full_Name")}
                value={values?.fullName}
                name="fullName"
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched?.fullName}
                errors={errors.fullName}
              />
            )}
            <PrimaryTextField
              title={t("Login.Email")}
              placeholder={t("Login.Enter_email")}
              value={values?.email}
              name="email"
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched?.email}
              errors={errors.email}
            />
            <PasswordTextFiled
              title={t("Login.Password")}
              name="password"
              value={values?.password}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched?.password}
              error={errors.password}
            />
          {switchAuth === 1 &&  <DialogModel 
              termsDialogOpen={termsDialogOpen}
              handleCloseTermsDialog={handleCloseTermsDialog}
              handleTermsAcceptance={handleTermsAcceptance}
              termsAccepted={termsAccepted}
              handleOpenTermsDialog={handleOpenTermsDialog}
               />}

            <PrimaryButton
              style={{ width: "100%", mt: "1rem" }}
              text={switchAuth === 1 ? t("Login.Signup") : t("Login.Login")}
              loading={loading}
              disabled={switchAuth === 1 && !termsAccepted}
            />
            {switchAuth === 0 && ProfileData?.type !== "guest" ? (
              <Box sx={{ width: "100%", my: "16px" }}>
                <Typography
                  variant="subtitle2"
                  textAlign="left"
                  sx={{
                    color: "#7C4CE1",
                    textDecoration: "none", // No underline by default
                    "&:hover": {
                      textDecoration: "underline", // Underline on hover
                    },
                  }}
                  onClick={() => {
                    navigate("/forget-password");
                    handleClose();
                  }}
                >
                  {t("Login.Forget_Password")}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ height: "16px" }} />
            )}
            {switchAuth === 0 && (
              <Divider
                sx={{
                  fontSize: "12px",
                  width: "100%",
                  color: "#9CA3AF",
                }}
              >
                {t("Login.or")}
              </Divider>
            )}
            {switchAuth === 0 && 
            // ProfileData?.type !== "guest" &&
             (
            <Button
              fullWidth
              variant="outlined"
              sx={{ mt: "1rem" }}
              onClick={openGoogleConsentScreen}
            >
              <img
                src={Googleicon}
                alt="goolgle"
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "1rem",
                }}
              />
              {t("Login.Signin_With_Google")}
            </Button>
            )}
            {/* {switchAuth === 0 && ProfileData?.type !== "guest" && (
              <Button
                variant="outlined"
                sx={{
                  width: "100%",
                  mt: "12px",
                  border: "2px solid #7C4CE1",
                  color: "#7C4CE1",
                }}
                onClick={() => {
                  handleClose();
                  GuestUserFun();
                }}
              >
                {t("AuthModel.Continue_Guest")}
              </Button>
            )} */}
            <Typography
              sx={{
                fontWeight: "400",
                mt: "16px",
                color: "#667085",
              }}
              variant="h6"
            >
              {t(
                switchAuth === 1
                  ? "Sign_Up.Already_have_an_account"
                  : `Login.Don't_have_a_account`
              )}{" "}
              <Typography
                onClick={() => setSwitchAuth(switchAuth === 1 ? 0 : 1)}
                variant="h6"
                component="span"
                sx={{
                  fontWeight: "400",
                  fontSize: "1rem !important",
                  color: "#7C4CE1",
                  cursor: "pointer",
                  textDecoration: "none", // No underline by default
                  "&:hover": {
                    textDecoration: "underline", // Underline on hover
                  },
                }}
              >
                {switchAuth === 1 ? t("Login.Login") : t("Login.Signup")}
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AuthModel;

const BoxStyle = {
  p: "16px",
  width: "100%",
  transition: "all .4s",
};
