import { createSlice } from "@reduxjs/toolkit";

export interface UserState {
  fontFamily: string;
  colorPallets: any;
  colorPalletsCustom: any;
  responsivenessValue: number;
  saveCode: boolean;
  Layers: any;
  openGlobalFontFamily: boolean;
  openGlobalPallets: boolean;
  openGlobalTheme: boolean;
  openExportModelMobile: boolean;
  coordinates: any;
  selectedElementId: string;
  selectedElementCode: any;
  selectedTage: string;
  emailCredintials: boolean;
  updateHTMLDocument: any;
  openVerifyEmail: boolean;
  propertiesValue: number;
  fullStackOptions: {};
  showUpgradeModal: boolean;
  profileTabs: number;
  chabtbotModel: boolean;
  commentsModel: boolean; AIEditorToggle: boolean;
  editLimitReached: boolean;
}

const initialState: UserState = {
  fontFamily: "",
  colorPallets: {},
  colorPalletsCustom: {},
  responsivenessValue: 0,
  saveCode: false,
  Layers: [],
  openGlobalFontFamily: false,
  openGlobalPallets: false,
  openGlobalTheme: false,
  coordinates: null,
  selectedElementId: null,
  selectedElementCode: null,
  updateHTMLDocument: null,
  selectedTage: null,
  emailCredintials: false,
  openExportModelMobile: false,
  openVerifyEmail: false,
  propertiesValue: 0,
  fullStackOptions: { chatbot: true, pages: false },
  profileTabs: 0,
  chabtbotModel: true,
  commentsModel: false, AIEditorToggle: true,
  showUpgradeModal: false,
  editLimitReached: false,
};

const toolsSlice = createSlice({
  name: "tools",
  initialState,
  reducers: {
    setFontFamily: (state, action) => {
      state.fontFamily = action.payload;
    }, setAIEditorToggle: (state, action) => {
      state.AIEditorToggle = action.payload;
    },
    setchabtbotModel: (state, action) => {
      state.chabtbotModel = action.payload;
    },
    setCommentsModel: (state, action) => {
      state.commentsModel = action.payload;
    },
    setFullStackOptions: (state, action) => {
      const { key, value } = action.payload;
      if (state.fullStackOptions.hasOwnProperty(key)) {
        state.fullStackOptions[key] = value;
      }
    },
    setColorPallets: (state, action) => {
      state.colorPallets = action.payload;
      state.saveCode = true;
    },
    setProfileTabs: (state, action) => {
      state.profileTabs = action.payload;
    },
    setCustomColorPallets: (state, action) => {
      state.colorPalletsCustom = action.payload;
    },
    setResponsivenessValue: (state, action) => {
      state.responsivenessValue = action.payload;
    },
    setSaveCode: (state, action) => {
      state.saveCode = action.payload;
    },
    setOpenGlobalFontFamily: (state, action) => {
      state.openGlobalFontFamily = action.payload;
    },
    setOpenGlobalPallets: (state, action) => {
      state.openGlobalPallets = action.payload;
    },
    setOpenGlobalTheme: (state, action) => {
      state.openGlobalTheme = action.payload;
    },
    setEmailCredintials: (state, action) => {
      state.emailCredintials = action.payload;
    },
    setLayers: (state, action) => {
      state.Layers = action.payload;
    },
    setCoordinates: (state, action) => {
      state.coordinates = action.payload;
    },
    setSelectedElementId: (state, action) => {
      state.selectedElementId = action.payload;
    },
    setSelectedElementCode: (state, action) => {
      state.selectedElementCode = action.payload;
    },
    setSelectedTage: (state, action) => {
      state.selectedTage = action.payload;
    },
    setUpdateHTMLDocument: (state, action) => {
      state.updateHTMLDocument = action.payload;
    },
    setPropertiesValue: (state, action) => {
      state.propertiesValue = action.payload;
    },
    setOpenExportModelMobile: (state, action) => {
      state.openExportModelMobile = action.payload;
    },
    setOpenVerifyEmail: (state, action) => {
      state.openVerifyEmail = action.payload;
    },
    setShowUpgradeModal: (state, action) => {
      state.showUpgradeModal = action.payload;
    },
    setEditLimitReached: (state, action) => {
      state.editLimitReached = action.payload;
    },
    setClearTools: (state) => {
      state.selectedTage = null;
      state.selectedElementId = null;
      state.coordinates = null;
      state.openGlobalFontFamily = false;
      state.openGlobalPallets = false;
      state.openGlobalTheme = false;
      state.emailCredintials = false;
      state.propertiesValue = 0;
      state.commentsModel = false;
      state.selectedElementCode = null;
    },
    setAllToolsReset: (state) => {
      state.openGlobalFontFamily = false;
      state.openGlobalPallets = false;
      state.openGlobalTheme = false;
      state.emailCredintials = false;
      state.propertiesValue = 0;
      state.commentsModel = false;
    },
    setFullStackTools: (state) => {
      state.fullStackOptions = { chatbot: false, pages: false };
    },
  },
});

export const {
  setFontFamily,
  setColorPallets,
  setCustomColorPallets,
  setResponsivenessValue,
  setSaveCode,
  setLayers,
  setOpenGlobalFontFamily,
  setOpenGlobalPallets,
  setOpenGlobalTheme,
  setCoordinates,
  setSelectedElementId,
  setSelectedTage,
  setUpdateHTMLDocument,
  setOpenExportModelMobile,
  setOpenVerifyEmail,
  setClearTools,
  setPropertiesValue,
  setFullStackOptions,
  setFullStackTools,
  setEmailCredintials,
  setProfileTabs,
  setchabtbotModel,
  setCommentsModel,
  setAllToolsReset,
  setSelectedElementCode, setAIEditorToggle,
  setShowUpgradeModal,
  setEditLimitReached,
} = toolsSlice.actions;
export default toolsSlice.reducer;
