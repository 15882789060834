import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Chip,
  FormHelperText,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Plus, X, ChevronDown, Trash2 } from 'lucide-react';
import PrimaryTextField from '../textField/PrimaryTextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const AddOrganizationModel = (props) => {
  const { setOpen,open } = props;

  const [teamEmails, setTeamEmails] = React.useState<string[]>([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Define validation schema using Yup
  const validationSchema = Yup.object({
    name: Yup.string().required('Organization name is required'),
    useCase: Yup.string().required(
      'Please select what you plan to use icōd for'
    ),
    email: Yup.string().email('Please enter a valid email address'),
  });

  // Formik object for managing form state and validation
  const formik = useFormik({
    initialValues: {
      name: '',
      useCase: '',
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Include the team emails in the submission
      console.log({ ...values, teamEmails });
    },
  });
  const {
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    touched,
    errors,
    setFieldValue,
  } = formik

  const handleClose = () => {
    setOpen(false);
  };

  // Memoize handleAddEmail to avoid stale closures in the event listener
  const handleAddEmail = React.useCallback(async () => {
    if (!values.email) {
      return; // Don't do anything if the email field is empty
    }

    try {
      // Validate email using Yup
      await Yup.string()
        .email('Please enter a valid email address')
        .validate(values.email);

      // Check for duplicate email
      if (teamEmails.includes(values.email)) {
        formik.setFieldError('email', 'This email has already been added');
        return;
      }

      // Add valid email to the list
      setTeamEmails([...teamEmails, values.email]);
      setFieldValue('email', ''); // Clear the input field after adding
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        formik.setFieldError('email', error.message);
      }
    }
  }, [values.email, teamEmails, formik, setFieldValue]);

  // Add event listener for Enter key press
  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && document.activeElement?.getAttribute('name') === 'email') {
        e.preventDefault(); // Prevent form submission
        handleAddEmail();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleAddEmail]); // Only re-add the event listener when handleAddEmail changes

  const handleRemoveEmail = (emailToRemove: string) => {
    setTeamEmails(teamEmails.filter((email) => email !== emailToRemove));
  };

  return (
    <React.Fragment>
    

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '90%', md: '600px' },
            maxWidth: '800px',
            p: { xs: '12px', sm: '24px', md: '40px' },
            borderRadius: '8px',
          },
        }}
      >
        <Typography variant="body2">Set Up Your Organization</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <X />
        </IconButton>
        <DialogContent sx={{ minWidth: '320px', p: '0',  }}>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: '400', textAlign: 'left', py: '12px' }}
          >
            An organization helps you manage your projects, teams, and resources
            efficiently. Give your organization a name to get started!
          </Typography>

          <PrimaryTextField
            title="Organization Name"
            placeholder="Enter organization name"
            value={values?.name}
            name="name"
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched?.name}
            errors={errors.name}
          />
          <Box>
            <Typography variant="h6" sx={{ pb: '6px', fontWeight: '500' }}>
              What do you plan to use icōd for?
            </Typography>
            <TextField
              select
              name={'useCase'}
              placeholder={'Select here'}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              value={values?.useCase}
              sx={{
                minHeight: '46px',
                height: '46px',
                '& .MuiOutlinedInput-root': {
                  color: '#667085',
                  fontWeight: '400',
                  height: '46px',
                },
              }}
              inputProps={{
                'data-cy': 'linked_in-personaldetail',
              }}
              SelectProps={{
                IconComponent: () => (
                  <ChevronDown size={18} style={{ marginRight: '12px' }} />
                ),
              }}
              error={touched.useCase && Boolean(errors.useCase)}
            >
              <MenuItem value="study">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>Study</Box>
              </MenuItem>
              <MenuItem value="business">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  For Business
                </Box>
              </MenuItem>
            </TextField>
            <Box sx={wrapper}>
              {touched.useCase && Boolean(errors.useCase) && (
                <FormHelperText error sx={errorStyle}>
                  {touched.useCase && errors.useCase}
                </FormHelperText>
              )}
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <Box sx={{ width: '100%' }}>
                <PrimaryTextField
                  title="Add Team Members"
                  placeholder="Enter Email"
                  value={values?.email}
                  name="email"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched?.email}
                  errors={errors.email}
                />
              </Box>
              <IconButton
                sx={{
                  border: '1px solid #7C4CE1 !important',
                  mt: '12px',
                  height: '46px',
                  width: '46px',
                }}
                onClick={handleAddEmail}
              >
                <Plus color="#7C4CE1" />
              </IconButton>
            </Box>

            {teamEmails.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" sx={{ mb: 1, color: '#667085' }}>
                  Team Members:
                </Typography>
                <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                  {teamEmails.map((email, index) => (
                    <Chip
                      key={index}
                      label={email}
                      onDelete={() => handleRemoveEmail(email)}
                      deleteIcon={<Trash2 size={16} />}
                      sx={{
                        mb: 1,
                        bgcolor: '#F9FAFB',
                        color: '#667085',
                        '& .MuiChip-deleteIcon': {
                          color: '#F04438',
                          '&:hover': {
                            color: '#D92D20',
                          },
                        },
                      }}
                    />
                  ))}
                </Stack>
              </Box>
            )}
          </Box>

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              onClick={async () => {
                // Validate form using Yup
                const errors = await formik.validateForm();

                // Check if there are any validation errors
                if (Object.keys(errors).length === 0) {
                  // Submit the form with team emails
                  handleSubmit();
                  handleClose();
                } else {
                  // Touch all fields to show validation errors
                  Object.keys(values).forEach((field) => {
                    formik.setFieldTouched(field, true, true);
                  });
                }
              }}
              sx={{
                bgcolor: '#7C4CE1',
                color: 'white',
                '&:hover': {
                  bgcolor: '#6039B0',
                },
                px: 4,
                py: 1,
                borderRadius: '8px',
                textTransform: 'none',
                fontWeight: '500',
              }}
            >
              Create Organization
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default AddOrganizationModel;

const wrapper = {
  height: '20px',
  width: '100%',
  mt: '2px',
};

const errorStyle = { paddingLeft: '0', mt: 0 };
