import MainNavbar from '@/layout/navbar/mainNavbar';
import { Box, useTheme } from '@mui/material';
import PricingSection from './components/PricingSection';
import CustomFooter from '../home/components/UpdatedFooter';

const PricingPage = () => {
  const theme = useTheme();

  return (
    <Box sx={BoxStyle(theme)}>
      <Box sx={NavbarBoxStyle}>
        <MainNavbar />
      </Box>
      <Box sx={BodyBoxStyle}>
        {' '}
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Box
            id="icod-scroll-to-top-from-footer"
            sx={{
              mx: { xs: '1rem', sm: '3rem', md: '6rem', lg: '7rem' },
            }}
          >
            <PricingSection />
          </Box>
          <CustomFooter />
        </Box>
      </Box>
    </Box>
  );
};

export default PricingPage;
const NavbarBoxStyle = {
  position: 'sticky',
  top: 0,
  zIndex: 1100,
  height: '54px',
  mx: { xs: '1rem', sm: '3rem', md: '6rem', lg: '7rem' },
};
export const BoxStyle = (theme) => ({
  width: '100%',
  height: '100vh',
  maxWidth: '1920px',
  mx: 'auto',
  bgcolor: theme?.palette?.background?.paper,
});
const BodyBoxStyle = {
  height: 'calc(100vh - 54px)',
  width: '100%',
  overflow: 'auto',
};
