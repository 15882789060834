import { Theme } from "@mui/material";

export default function Tooltip(theme: Theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.black,
          padding: "8px 16px",
          boxShadow: theme.shadows[3], // ✅ adds subtle elevation
          fontSize: "0.875rem", // optional: make tooltip text a bit more readable
        },
        arrow: {
          color: theme.palette.common.white,
        },
      },
    },
  };
}
