import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, IconButton, Typography } from '@mui/material';
import { X } from 'lucide-react';
import { PrimaryDoubleTickIcon } from '@/assets/images';
import { motion } from 'framer-motion';
import { useAppDispatch, useAppSelector } from '@/redux/store';
import { setShowUpgradeModal } from '@/redux/slices/toolsSlice';
import { useNavigate } from 'react-router-dom';

const UpgradeModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const showUpgradeModal = useAppSelector(state => state.tools.showUpgradeModal);
  const [open, setOpen] = React.useState(showUpgradeModal);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    // setOpen(false);
    dispatch(setShowUpgradeModal(false));
  };
  React.useEffect(() => {
    setOpen(showUpgradeModal);
  }, [showUpgradeModal]);

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            width: { xs: '100%', sm: '90%', md: '800px' },
            maxWidth: '800px',
            p: { xs: '12px', sm: '24px', md: '40px' },
            borderRadius: '8px',
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <X />
        </IconButton>
        <DialogContent sx={{ minWidth: '320px', p: '0' }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: { xs: '12px', sm: '40px', md: '80px' },
              overflowX: "hidden"
            }}
          >
            <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
              <Typography variant="h3" sx={{ color: '#1E293B' }}>
                Unlock More Power with Pro Plan
              </Typography>
              <Typography variant="h3" sx={{ color: '#1E293B' }}>
                $15
                <span
                  style={{
                    fontSize: '16px',
                    fontWeight: '500',
                    color: '#667085',
                  }}
                >
                  /mo
                </span>
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: '400' }}>
                Looks like you’ve discovered one of our premium features! To
                continue using, you’ll need to upgrade your plan.{' '}
              </Typography>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  gap: '12px',
                  mt: { xs: '24px', sm: '36px', md: '40px' },
                }}
              >
                {/* <Button variant="outlined" sx={{ width: '50%', height: '46px' }}>
                  Explore More
                </Button> */}
                <Button
                  variant="contained"
                  sx={{ width: '50%', height: '46px' }}
                  onClick={() => {
                    navigate(`/pricing`);
                  }}
                >
                  Upgrade
                </Button>
              </Box>
            </Box>
            <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
              <Typography variant="body2">Included in Pro Plan</Typography>
              {features?.map((item, index) => (
                <Box
                  component={motion.div}
                  initial={{ opacity: 0, x: -300 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: item.duration, type: 'spring' }}
                  key={index}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <img
                    src={PrimaryDoubleTickIcon}
                    alt="tick"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: '400', ml: '12px', color: '#1E293B' }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default UpgradeModal;

const features = [
  { name: '1 user', duration: 2 },
  { name: '3 websites', duration: 2.2 },
  { name: '30 free edits', duration: 2.4 },
  { name: 'Subdomain on icōd', duration: 2.6 },
  { name: '50 MB free space', duration: 2.8 },
  { name: 'Pre defined templates', duration: 3 },
  { name: 'Ads or branding on the website', duration: 3 },
  { name: 'Site optimized for mobile', duration: 3 },
  { name: 'Free SSL/TLS certificates for 1st month', duration: 3 },
];

